<template>
  <div class="">
    <div class="editor__top-links">
      <router-link :to="{ name: 'editor-chat' }">Чат</router-link>
      <router-link :to="{ name: 'editor-collage' }">Воспоминания</router-link>
      <router-link :to="{ name: 'editor-article' }">Материалы</router-link>
      <span @click="$store.commit('setToken', null)">Log out</span>
    </div>
    <div v-if="isAuth">
      <router-view></router-view>
    </div>
    <div v-else class="login">
      <v-input label="Login" v-model="user"></v-input>
      <v-input label="Password" v-model="pass"></v-input>
      <v-button @click="send">Log in</v-button>
      <span>{{error}}</span>
    </div>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.css";
import {mapState} from "vuex";
import VInput from "@/components/basic/v-input";
import VButton from "@/components/basic/v-button";
import api from "@/api";

export default {
  name: "EditorPage",
  components: {VButton, VInput},
  data: function () {
    return {
      user: null,
      pass: null,
      error: null,
      isAuth: false
    }
  },
  computed: {
    ...mapState({
      authToken: state => state.token
    })
  },
  mounted() {
    this.$store.commit('setBackground', false)
    if (this.authToken) {
      this.login(this.authToken)
    }
  },
  methods: {
    send: function() {
      const token = btoa(this.user + ':' +this.pass)
      this.login(token)
    },
    login: async function (token) {
      const isAuth = await api.login(token)
      if (!isAuth) {
        this.error = 'Wrong user or password'
      } else {
        this.error = null
        this.$store.commit('setToken', token)
        api.setToken(token)
        this.isAuth = true
      }
    }
  }
};
</script>

<style lang="sass">
body, html
  height: 100vh

.login
  width: 300px
  background: #f2f2f2
  padding: 20px
  left: 50%
  position: relative
  transform: translateX(-50%)

.editor
  height: calc(100vh - 120px)
  &__top-links
    background: #474646
    display: flex
    align-items: center
    height: 30px
    padding: 0 10px
    a
      display: inline-block
      color: #ffffff
      padding: 0 10px
      font-size: 13px
  &__tools
    padding: 10px
    background: #f2f2f2
    border-bottom: 1px solid #dbdbdb
  &__list
    height: 100%
    overflow-y: scroll
    background: #f2f2f2
    min-width: 200px
    max-width: 250px !important
    display: flex
    flex-direction: column
    align-items: center
    padding: 0
    border-right: 1px solid #dbdbdb

    &-item
      background: #fafafa
      width: 100%
      border-bottom: 1px solid #dbdbdb
      padding: 5px 15px 5px 25px
      display: flex
      justify-content: space-between

      &:hover
        background: #fff

        .far
          display: inline-block

      &.--active
        background: #c5f5e6

      .far
        color: #8d8d8d
        display: none
        margin-left: 5px

        &:hover
          color: #444
          cursor: pointer

      &__link:hover
        cursor: pointer

      &__id
        color: #0e67a7
        display: inline-block
        margin-right: 10px

      &__index
        color: #888
        font-size: 12px
        display: inline-block
        margin-right: 10px
</style>
